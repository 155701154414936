var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { attrs: { id: "guest-profile" } },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [_c("CustomerProfileForm", { on: { focusChange: _vm.onFocusChange } })],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "pages.customer.customerProfile.CustomerProfileEdit.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }